let materialPopup = document.querySelector("#what-is-material");


if (materialPopup) {
    let wrapperModalMaterial = document.querySelector(".wrapper-modal-material")
    let platedMaterial = document.querySelector(".plated-material")
    let rhodium = document.querySelector(".rhodium-material")
    let rhodium_gold = document.querySelector(".rhodium-gold-material")
    let gold = document.querySelector(".gold-material")
    let antiTarnish = document.querySelector(".antiTarnish-material")

    window.addEventListener('click', function() {
        wrapperModalMaterial.setAttribute("data-isopen", "false")
        rhodium.setAttribute("data-isopen", "false")
        gold.setAttribute("data-isopen", "false")
        antiTarnish.setAttribute("data-isopen", "false")
        platedMaterial.style.display = "none";
    }, false);

    materialPopup.addEventListener('click', function(event) {
        event.stopPropagation();
        wrapperModalMaterial.setAttribute("data-isopen", "true")
        platedMaterial.style.display = "block";
        if (materialPopup.getAttribute("data-material") == "White Rhodium Plated") {
            rhodium.setAttribute("data-isopen", "true")
        }
        if (materialPopup.getAttribute("data-material") == "22K Gold Plated") {
            gold.setAttribute("data-isopen", "true")
        }
        if (materialPopup.getAttribute("data-material") == "Anti-Tarnish Plated") {
            antiTarnish.setAttribute("data-isopen", "true")
        }
        if (materialPopup.getAttribute("data-material") == "22K Gold Plated, White Rhodium Plated") {
            rhodium_gold.setAttribute("data-isopen", "true")
        }
    }, false);

    let closeMaterialPopup = document.querySelector("#icon-close-material-modal");
    if (closeMaterialPopup) {
        closeMaterialPopup.addEventListener('click', function(event) {
            event.stopPropagation();
            wrapperModalMaterial.setAttribute("data-isopen", "false")
            rhodium.setAttribute("data-isopen", "false")
            gold.setAttribute("data-isopen", "false")
            antiTarnish.setAttribute("data-isopen", "false")
            platedMaterial.style.display = "none";
        }, false);
    }

}